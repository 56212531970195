import angular from 'angular'
import $ from 'jquery'
import { Helper, TERMS, ApiError, SETTINGS } from '../../common'
import BaseSingleController from '../base.single'

const RESOURCETYPE = {
	LOGO: 'college_logo',
};

export default class CollegeSingleController extends BaseSingleController {
	static get $inject(){return [
		'$mdDialog',
		'$timeout',
		'parentStateParams',
		'googleMapsFactory',
	].concat(BaseSingleController.$inject)}

	static get DEFAULTS(){return {
		status: 'active', 
		// type: 'community_college',
		student_id_policy: 'disabled',
		student_id_validation: {
			error_message: '',
			regex_expression: '',
		},
		block_chatbot_invites: false,
		disable_chat: false,
		new_badges_enabled: true,
		task_notifications_disabled: false,
		campuses: [],
		contracts: [],
		goals: {},
		hide_name: false,
		sso: {}
	}}


	init(){
		this.mapping = {
			status: angular.extend({
				active: 'Active',
				inactive: 'Inactive',
				deleted: 'Deleted',
			}, this.MAPPINGS_JSON.colleges.status_key),
			// types: Helper.deepCopy(this.MAPPINGS_JSON.colleges.type_key),
			types: angular.extend({
				community_college: 'Community College',
				partner: 'Partner',
			}),
			terms: this.MAPPINGS_JSON.colleges?.term_key || TERMS,
			student_id_policies: Helper.deepCopy(this.MAPPINGS_JSON.colleges.student_id_policy),
			enableDisable: {
				'Enabled': 'true',
				'Disabled': 'false',
			},
			reversedEnableDisable: {
				'Enabled': 'false',
				'Disabled': 'true',
			},
			yesNo: {
				'Yes': 'true',
				'No': 'false',
			},
			timezones: moment.tz.names(),
		};

		this._$mobileRegex = /^(\+\d{1,3})?(\d{10})\b/;

		this._destructors.push(
			this.$scope?.$once('deps-loaded', ()=>{
				if ( this.data ) {
					this.model = this.createModel(this.data);
				} else {
					this.model = this.createModel({timezone: 'US/Pacific'});
					this.addNewCampus();
					this.addNewContract();
				}
				this._$timezones = this.queryTimezone(this._$tz, this.model.timezone);
				this._$open = this.model.student_id_policy == 'enabled' || this.model.student_id_policy == 'required';
			}),
			()=>{ // clear google maps variables
				Object.values(this._gmaps).forEach(obj=>{
					if ( obj.map ) {
						if ( obj.mapClickHandle )
							obj.map.removeListener(obj.mapClickHandle);
						obj.map = null;
					}
				});
			},
		);

		this._gmaps = {};

		super.init();
	}
	_loadDependencies(){
		return this.$q.all([
				this.apiMap.getTags().then(data=>this.mapping.flutterTags = data),
				this.apiMap.getAllDistricts().then(data=>this.mapping.districts = data),
				this.$q.when(this.googleMapsFactory).then(google=>{
					this.google = google;
					this.geocoder = new google.maps.Geocoder();
				}),
			])
			.then(()=>super._loadDependencies());
	}


	createModel(data){
		let model = angular.extend({}, Helper.deepCopy(CollegeSingleController.DEFAULTS), Helper.deepCopy(data));
		model.ancestry = 'v2';
		model.logo_url = (this.$state.params.uploads || []).find(file=>file.resourceType==RESOURCETYPE.LOGO) || model.logo_url || undefined;
		model.logo_alt_text = model.logo_alt_text || undefined;
		model.enable_sso = model.sso != null || false;
		model.sso = model.enable_sso ? model.sso : undefined;
		model.campuses.forEach((campus)=>{
			campus.latitude = campus.latitude ? parseFloat(campus.latitude) : undefined;
			campus.longitude = campus.longitude ? parseFloat(campus.longitude) : undefined;
			campus.zip_code = campus.zip_code ? parseInt(campus.zip_code) : undefined;
		});
		model.contracts.forEach(contract=>{
			contract.total_allowed_notifications = +parseInt(contract.total_allowed_notifications);
			contract.start_date = moment(contract.start_date, 'YYYY-MM-DD');
			contract.end_date = moment(contract.end_date, 'YYYY-MM-DD');
		});
		model.goals = Object.values(model.goals || {}).map(goal=>({
			target: goal.target,
			start_date: moment(goal.start_date, 'YYYY-MM'),
			end_date: moment(goal.end_date, 'YYYY-MM'),
		}));

		model.hide_name = (model.hide_name || false).toString();
		model.test = (model.test || false).toString();
		model.new_badges_enabled = (model.new_badges_enabled || false).toString();
		model.block_chatbot_invites = (model.block_chatbot_invites || false).toString();
		model.disable_chat = (model.disable_chat || false).toString();
		model.task_notifications_disabled = (model.task_notifications_disabled || false).toString();
		model.resources = Object.keys(model.resources || {}).map(k=>{
			let rsrc = model.resources[k];
			rsrc._id = +k;
			rsrc.name = rsrc.name && rsrc.name.en_US || null;
			rsrc.description = rsrc.description && rsrc.description.en_US || null;
			rsrc.location = rsrc.location || {};
			rsrc.tags = Object.keys(rsrc.tags || {});
			if ( rsrc.location.label ) {
				rsrc.location.label = rsrc.location.label.en_US;
			}
			if ( rsrc.phone_number ) {
				let m = rsrc.phone_number.match(this._$mobileRegex);
				if ( m && m[1] == '+1' ) // remove +1 country code
					rsrc.phone_number = m[2];
			}
			return rsrc;
		});
		return model;
	}


	submit($evt, form){
		this._validateForm(form)
			.then(()=>this._preparePayload())
			.then(payload=>{
				if ( this.data ) {
					console.log(this.data, payload);
					return this.api.put('colleges/'+ this.data._id, payload)
						.then(res=>this._uploadFiles(this.data._id, payload)
							.then(()=>res)
						)
						.then(res=>{
							this.toast.success('College Updated');
							return res;
						});
				} else {
					return this.api.post('colleges', payload)
						.then(res=>this.api.get('colleges/'+ res.data._id)
								.then(()=>this._uploadFiles(res.data._id, payload)
										.then(()=>res)
							)
						)
						.then(res=>{
							this.toast.success('New College Saved');
							return res;
						});
				}
			})
			.then(res=>{
				this.promptExit.disable();
				this.$state.go('^', this.data && this.parentStateParams || {});
			}, err=>{
				if ( this.api.isApiError(err) ) {
					err.name = 'Unable to Save';
					this.toast.error(err);
				}
			})
			.finally(()=>this.isBusy = false);
		this.isBusy = true;
	}

	_preparePayload(){
		let payload = Helper.deepCopy(this.data || {});
		let model = Helper.deepCopy(this.model);
		this._filesToUpload = [];

		payload.status = model.status;
		payload.type = model.type;
		payload.name = model.name;
		payload.website = model.website;
		payload.ancestry = model.ancestry;
		payload.hide_name = model.hide_name=='true';
		payload.test = model.test=='true';
		payload.new_badges_enabled = model.new_badges_enabled=='true';

		if ( model.type != 'partner' ) {
			payload.term = model.term;
			payload.district_id = +model.district_id;
			payload.block_chatbot_invites = model.block_chatbot_invites=='true';
			payload.disable_chat = model.disable_chat=='true';
			payload.task_notifications_disabled = model.task_notifications_disabled=='true';
		}
			payload.student_id_policy = model.student_id_policy;
			if ( payload.student_id_policy === 'enabled' || payload.student_id_policy === 'required' ) {
				payload.student_id_validation = {
					error_message: model.student_id_validation.error_message,
					regex_expression: model.student_id_validation.regex_expression,
				}
		}

		payload.timezone = model.timezone;
		payload.logo_url = model.logo_url || undefined;
		payload.logo_alt_text = model.logo_alt_text || undefined;

		if ( this.model.logo_url__file ) {
			let file = this.model.logo_url__file;
			file.crawlPathValue = 'logo_url';
			file.resourceType = RESOURCETYPE.LOGO;
			this._filesToUpload.push(file);
		}

		if (model.type != "partner" && model.enable_sso) {
			payload.sso = {
				type: "saml",
				saml_assertion_url: model.sso.saml_assertion_url,
				saml_sp_identifier: model.sso.saml_sp_identifier,
				saml_metadata_url: model.sso.saml_metadata_url || undefined,
			}
		} else {
			payload.sso = undefined;
		}

		if ( model.type == 'partner' ) {
			payload.amazon = !! model.amazon;
			payload.salesforce_shortname = model.salesforce_shortname;
			payload.description = model.description;
			payload.display_landing_page = !! model.display_landing_page;
			payload.landing_page_button_text = model.landing_page_button_text || undefined;
			payload.landing_page_button_url = model.landing_page_button_url || undefined;
		} else {
			payload.salesforce_id = model.salesforce_id || undefined;
			payload.amazon = undefined;
		}

		if ( model.type == 'partner' ) {
			return payload;
		}

		payload.campuses = [];
		model.campuses.forEach(campus=>payload.campuses.push({
			status: campus.status,
			name: campus.name,
			phone: campus.phone || undefined,
			website: campus.website ? campus.website.replace(/\s/g, '%20') : undefined,
			zip_code: campus.zip_code ? +campus.zip_code : undefined,
			latitude: campus.latitude ? +campus.latitude : undefined,
			longitude: campus.longitude ? +campus.longitude : undefined,
		}));

		payload.contracts = model.contracts.map(contract=>({
			plan: contract.plan,
			start_date: moment(contract.start_date).format('YYYY-MM-DD'),
			end_date: moment(contract.end_date).format('YYYY-MM-DD'),
			total_allowed_notifications: contract.total_allowed_notifications, // make string
		}));

		payload.goals = {};
		model.goals.forEach((goal, i)=>{
			payload.goals[i+1] = {
				start_date: moment(goal.start_date).format('YYYY-MM'),
				end_date: moment(goal.end_date).format('YYYY-MM'),
				target: goal.target,
			};
		});

		payload.resources = {};
		model.resources.forEach(rsrc=>{
			let phone_number = (rsrc.phone_number || '').trim() || undefined;
			if ( phone_number ) {
				let m = phone_number.match(this._$mobileRegex);
				if ( m ) {
					if ( ! m[1] ) // no country code
						m[1] = '+1';
					phone_number = m[1] + m[2];
				}
			}
			let tags = {};
			(rsrc.tags || []).forEach(key=>tags[key]={});

			payload.resources[rsrc._id] = {
				name: {en_US: rsrc.name},
				description: rsrc.description ? {en_US: rsrc.description} : undefined,
				location: rsrc.location && (Helper.isValidValue(rsrc.location.label || rsrc.location.latitude)) ? {
					label: Helper.isValidValue(rsrc.location.label) ? {en_US: rsrc.location.label} : undefined,
					longitude: Helper.isValidValue(rsrc.location.longitude) ? +rsrc.location.longitude : undefined,
					latitude: Helper.isValidValue(rsrc.location.latitude) ? +rsrc.location.latitude : undefined,
				} : undefined,
				link_url: rsrc.link_url || undefined,
				phone_number: phone_number,
				tags: tags,
			}
		});
		return payload;
	}

	_validateForm(form){
		let promise = this.$q.resolve(true);  // use this to chain promises

		if ( Object.keys(form.$error || {}).length > 0 ) {
			let $first;
			Object.values(form.$error).forEach(list=>list.forEach($item=>{
				if ( ! $first ) $first = $item;
				$item.$setTouched();
			}));
			promise = promise.then(()=>this.$q.reject(true));
			$first && $first.$$element.focus();
		}
		return promise;
	}

	_uploadFiles(id, payload){
		if ( ! this._filesToUpload.length )
			return this.$q.when(true);

		payload._id = id;
		id = ''+ id; // make sure id is string
		// make sure id is included in the payload
		let successes = 0, failures = 0;
		let promises = this._filesToUpload.map(file=>{
			let cfg = {
					params: {
						resourceType: file.resourceType,
						contentType: file.type,
						metadata: (file.metadata || []).concat('college_id:'+id),
					},
					level: ApiError.LEVEL.MANUAL,
			};
			console.info('uploading', file);

			return this.api.upload('upload', {file}, cfg)
				.then(res=>{
					successes++;
					Helper.setCrawlValue(payload, file.crawlPathValue.split('.'), res.data.url);
					console.info('upload success', file, res.data.url);
				})
				.catch(err=>{
					failures++;
					file._$uploadError = err;
					console.info('upload failed', file, err);
					throw err;
				});
		});

		return this.$q.all(promises)
			.then(()=>{
				console.info('upload complete', successes, failures);
				if ( successes > 0 ) { // any success, update resource
					console.info('updating resource');
					return this.api.put(`colleges/${id}`, payload, {level: ApiError.LEVEL.MANUAL})
						.then(()=>{ if (failures > 0) throw false; })
				} else
				if (failures > 0) {
					throw false;
				}
			})
			.catch(()=>{
				let files = this._filesToUpload.filter(file=>file._$uploadError);
				let err = new Error('College saved but some file(s) failed to upload.');
				err.name = 'Upload Error';
				err.debug = files.map(file=>file.name +' '+ file._$uploadError.code +' '+ file._$uploadError.message).join('\n\r');

				files.forEach(file=>delete file._$uploadError);
				if ( ! this.data ) { // from add page, move to edit page
					this.promptExit.disable();
					this.$state.go('app.colleges.edit', {id, uploads: this.uploads})
						.then(()=>this.errorPrompt.show(err));
				} else {
					this.errorPrompt.show(err);
				}

				throw err;
			});
	}



	delete($ev){
		this.$mdDialog.show(
			this.$mdDialog.confirm()
					.title('Are you sure you want to delete this college?')
					.ariaLabel('confirm delete')
					.targetEvent($ev)
					.ok('Delete')
					.cancel('Cancel')
		).then(()=>{
			this.isBusy = true;
			return this.api.delete('colleges/'+ this.data._id)
				.finally(()=>this.isBusy = false);
		})
		.then(()=>{
			this.promptExit.disable();
			this.toast.success(`Deleted College #${this.data._id}`);
			this.$state.go('^', this.session.get('colleges') || {});
		});
	}


	addNewCampus(autoFocus){
		this.model.campuses.push({
			status: 'active',
			name: '',
			phone: '',
			website: '',
			zip_code: '',
			latitude: '',
			longitude: '',
			_$open: true,
		});
		if (autoFocus)
			this.$timeout(()=>$('[name="campus_'+ (this.model.campuses.length - 1) +'_name"]').focus(), 100);
	}
	removeCampus($index, $ev){
		var confirm = this.$mdDialog.confirm()
						.title('Remove Campus')
						.textContent('Are you sure you want to remove this campus?')
						.ariaLabel('Remove Campus')
						.targetEvent($ev)
						.ok('Ok')
						.cancel('Cancel');

		this.$mdDialog.show(confirm).then(()=>{
			this.model.campuses.splice($index, 1);
		}, ()=>{});
	}

	addNewContract(autoFocus){
		this.model.contracts.push({
			plan: 'Basic',
			start_date: moment(),
			end_date: moment().add(10, 'years'),
			total_allowed_notifications: 10000000,
		});
		if ( autoFocus )
			this.$timeout(()=>$('[name="contract_'+ (this.model.contracts.length - 1) +'_plan"]').focus(), 100);
	}

	removeContract($index, $ev){
		var confirm = this.$mdDialog.confirm()
							.title('Remove Contract')
							.textContent('Are you sure you want to remove this contract?')
							.ariaLabel('Remove Contract')
							.targetEvent($ev)
							.ok('Ok')
							.cancel('Cancel');

		this.$mdDialog.show(confirm).then(()=>{
			this.model.contracts.splice($index, 1);
		}, ()=>{});
	}

	addNewGoal(autoFocus){
		this.model.goals.push({
			start_date: moment(),
			end_date: moment(),
			target: 100,
		});
		if ( autoFocus )
			this.$timeout(()=>$('[name="goal_'+ (this.model.goals.length - 1) +'_target"]').focus(), 100);
	}

	removeGoal($index, $ev){
		var confirm = this.$mdDialog.confirm()
								.title('Remove Goal')
								.textContent('Are you sure you want to remove this goal?')
								.ariaLabel('Remove Goal')
								.targetEvent($ev)
								.ok('Ok')
								.cancel('Cancel');

		this.$mdDialog.show(confirm).then(()=>{
			this.model.goals.splice($index, 1);
		}, ()=>{});
	}

	addNewResource(autoFocus){
		let lastID = 0;
		this.model.resources.forEach(rsrc=>rsrc._id > lastID ? (lastID = rsrc._id) : null);
		let rsrc;
		this.model.resources.push(rsrc = {
			_id: lastID+1,
			name: '',
			location: {},
			tags: [],
		});
		if ( autoFocus )
			this.$timeout(()=>$('[name="resource_'+ (this.model.resources.length - 1) +'_name"]').focus(), 100);
		return rsrc;
	}

	removeResource($index, $ev){
		var confirm = this.$mdDialog.confirm()
								.title('Remove Resource')
								.textContent('Are you sure you want to remove this resource?')
								.ariaLabel('Remove Resource')
								.targetEvent($ev)
								.ok('Ok')
								.cancel('Cancel');

		this.$mdDialog.show(confirm).then(()=>{
			this.model.resources.splice($index, 1);
		}, ()=>{});
	}

	getLinkDomain(link){
		return Helper.getLinkDomain(link);
	}

	getGeoLatitude(value){
		return (value < 0 ? 'S' : 'N') + Math.abs(value);
	}

	getGeoLongitude(value){
		return (value < 0 ? 'W' : 'E') + Math.abs(value);
	}

	queryTimezone(str, value){
		if ( str ) {
			str = str.toLowerCase().replace(/\s+/, '_');
			return this.mapping.timezones.filter(name=>name.toLowerCase().indexOf(str) > -1);
		} else {
			if ( value && !SETTINGS.TZ_DEFAULTS.includes(value) )
				return [value].concat(SETTINGS.TZ_DEFAULTS);
		}
		return SETTINGS.TZ_DEFAULTS;
	}

	showMap(rsrc){
		if ( ! this._gmaps[rsrc._id] ) {
			const google = this.google;
			const obj = this._gmaps[rsrc._id] = {};
			obj.map = new google.maps.Map($('#map_'+ rsrc._id).get(0), {
				zoom: rsrc.location.latitude ? 16 : 4,
				center: new google.maps.LatLng(+rsrc.location.latitude||37.2756024, +rsrc.location.longitude||-104.6568059),
				disableDefaultUI: true,
				zoomControl: true,
				gestureHandling: 'cooperative',
			});
			obj.marker = new google.maps.Marker({
				map: obj.map,
				position: new google.maps.LatLng(+rsrc.location.latitude, +rsrc.location.longitude),
			});
			obj.mapClickHandle = obj.map.addListener('click', mmevent=>{
				if ( obj.marker )
					obj.marker.setPosition(mmevent.latLng);
				else
					obj.marker = new google.maps.Marker({map: obj.map, position: mmevent.latLng});

				const confirm = this.$mdDialog.confirm()
					.title('Pin Location')
					.textContent('Are you sure you want to set the pin here?')
					.ariaLabel('Pin Location')
					.ok('Ok')
					.cancel('Cancel');

				this.$mdDialog.show(confirm).then(()=>{
					rsrc.location.latitude = mmevent.latLng.lat();
					rsrc.location.longitude = mmevent.latLng.lng();
				}, ()=>{
					obj.marker.setPosition(new google.maps.LatLng(+rsrc.location.latitude, +rsrc.location.longitude));
				});

				this.$scope.$applyAsync();
			});
			this.$scope.$applyAsync();
		}
		return rsrc;
	}

	hasMap(rsrc){
		return !!this._gmaps[rsrc._id];
	}

	searchLocation(rsrc, addr){
		if ( this.geocoder ) {
			addr = Helper.convertToLangSpan(addr);
			let defer = this.$q.defer();
			this.geocoder.geocode({address:addr}, (results, status)=>{
				if ( status == 'OK' || status == 'ZERO_RESULTS' ) {
					console.log(results);
					defer.resolve(status == 'OK' ? results : []);
				} else {
					let err = new ApiError('Maps is unavailable', null, status);
					this.toast.error(err);
					defer.reject(err);
				}
				this.showMap(rsrc);
				this.$scope.$evalAsync();
			});
			return defer.promise;
		}
		this.showMap(rsrc);
		return [];
	}

	selectLocation(rsrc, item){
		if ( item ) {
			const google = this.google;
			const obj = this._gmaps[rsrc._id] = this._gmaps[rsrc._id] || {};

			if ( ! obj.map )
				obj.map = new google.maps.Map($('#map_'+ rsrc._id).get(0), {center: item.geometry.location});
			else
				obj.map.setCenter(item.geometry.location);
			obj.map.fitBounds(item.geometry.viewport);

			if ( obj.marker )
				obj.marker.setPosition(item.geometry.location);
			else
				obj.marker = new google.maps.Marker({map: obj.map, position: item.geometry.location});

			rsrc.location.latitude = item.geometry.location.lat();
			rsrc.location.longitude = item.geometry.location.lng();
		}
	}

	isCoordRequired(rsrc){
		return rsrc.location && (/^-?\d+\.?\d*$/.test(rsrc.location.latitude) || /^-?\d+\.?\d*$/.test(rsrc.location.longitude))
	}

	cleanMobileNumber($model){
		this.$timeout(()=>{
			let value = String($model.$viewValue || '');
			if ( /[\(\)\-\s]/g.test(value) )
				value = value.replace(/[\(\)\-\s]/g, '');
			$model.$setViewValue(value);
			$model.$render();
		}, 200);
	}
}