<header class="md-whiteframe-2dp flex-none layout-row layout-align-space-between-start layout-wrap">
	<h2 id="pageTitle" class="md-headline flex-xs-100">
		<a ui-sref="^" ui-sref-opts="ctrl.parentStateParams">Colleges <span class="margin-1x" aria-hidden="true">&#9656;
		</span></a>{{ ctrl.data ? '' : 'Add New' }}
		<span id="docTitle" ng-show="ctrl.data">{{:: ctrl.data ? ctrl.data.name : 'new college'}}</span>
		<span role="img" aria-label="changes not saved" title="changes not saved" ng-show="form.$dirty">*</span>
	</h2>
	<div class="top-buttons flex-xs-100" ng-show="ctrl.data" form-disabled="ctrl.isBusy">
		<md-button class="md-warn" id="deleteBtn" aria-labelledby="deleteBtn docTitle" 
                       ng-click="ctrl.delete($event)"
                       ng-if=":: mainCtrl.hasPermission(PERMISSIONS.DELETE_COLLEGES)"
                       ng-hide="ctrl.isLocked">
                       <span class="far fa-trash-alt"></span> 
      Delete
		</md-button>
	</div>
</header>

<form id="college-form" name="form" novalidate 
       class="flex layout-column"
       form-disabled="ctrl.isBusy"
	ng-submit="!ctrl.isBusy && ctrl.submit($event, form)">
	<main flex>
		<div class="panel panel-small" md-whiteframe="1">
			<div class="panel-title md-padding md-title normal-wg">College Details</div>
			<div class="panel-content md-padding">
				<div layout="row" layout-wrap toggle-expand="ctrl.model.type!='partner'">
					<md-input-container flex-xs="100" flex-gt-xs="75" class="md-input-has-placeholder">
						<label>Type</label>
						<md-select name="type" ng-model="ctrl.model.type" required>
							<md-option ng-repeat="(key, value) in ctrl.mapping.types" ng-value="key">{{value}}</md-option>
						</md-select>
						<div class="md-errors-spacer"></div>
						<div ng-messages="form.type.$error" role="alert">
							<div ng-message="required">Required</div>
						</div>
					</md-input-container>
					<md-input-container flex-xs="100" flex-gt-xs="25" class="md-input-has-placeholder">
						<label>Status</label>
						<md-select name="status" ng-model="ctrl.model.status">
							<md-option ng-repeat="(key, value) in ctrl.mapping.status" ng-value="key">{{value}}</md-option>
						</md-select>
						<div class="md-errors-spacer"></div>
					</md-input-container>

					<md-input-container flex="100" class="md-input-has-placeholder">
						<label>Name</label>
						<input type="text" name="name" autocomplete="organization" ng-model="ctrl.model.name" required md-autofocus>
						<div ng-messages="form.name.$error" role="alert">
							<div ng-message="required">Required</div>
						</div>
					</md-input-container>

					<section class="expandable" flex="100">
						<div layout="row" layout-wrap form-disabled="ctrl.model.type=='partner'">
							<md-input-container flex-xs="100" flex-gt-xs="50" class="md-input-has-placeholder">
								<label>Term</label>
								<md-select name="type" ng-model="ctrl.model.term" ng-required="ctrl.model.type!='partner'">
									<md-option ng-repeat="(key, value) in ctrl.mapping.terms" ng-value="key">{{value}}</md-option>
								</md-select>
								<div class="md-errors-spacer"></div>
							</md-input-container>

							<md-input-container flex="100" class="md-input-has-placeholder">
								<label>District</label>
								<md-select name="district" ng-model="ctrl.model.district_id" ng-model-options="{trackBy: '$value'}" ng-required="ctrl.model.type!='partner'">
									<md-option ng-repeat="item in ctrl.mapping.districts" ng-value="+item._id">{{item.name}}</md-option>
								</md-select>
								<div class="md-errors-spacer"></div>
								<div ng-messages="form.district.$error" role="alert">
									<div ng-message="required">Required</div>
								</div>
							</md-input-container>
						</div>
					</section>
					<md-input-container flex="100" class="md-input-has-placeholder">
						<label>Website</label>
						<input type="url" name="website" ng-model="ctrl.model.website" required>
						<div ng-messages="form.website.$error" role="alert">
							<div ng-message="required">Required</div>
							<div ng-message="url">Use a valid url format, ex: <em>https://example.com</em></div>
						</div>
					</md-input-container>
					<md-input-container class="md-block no-animate" flex-xs="100" flex-gt-xs="33"
						ng-show="ctrl.model.type=='community_college'">
						<md-checkbox ng-model="ctrl.model.enable_sso" ng-true-value="true"
							ng-false-value="false">SSO</md-checkbox>
					</md-input-container>
					<div class="flex-100" ng-show="ctrl.model.enable_sso==true">
						<section class="expandable" flex="100">
							<div layout="row" layout-wrap>
								<md-input-container flex="100" class="md-input-has-placeholder">
									<label>SAML Assertion URL</label>
									<input type="url" name="website" ng-model="ctrl.model.sso.saml_assertion_url"
										ng-required="ctrl.model.enable_sso==true">
									<div ng-messages="form.website.$error" role="alert">
										<div ng-message="required">Required</div>
										<div ng-message="url">Use a valid url format, ex: <em>https://example.com</em>
										</div>
									</div>
								</md-input-container>
								<md-input-container flex="100" class="md-input-has-placeholder">
									<label>SAML SP Identifier</label>
									<input type="url" name="website" ng-model="ctrl.model.sso.saml_sp_identifier"
										ng-required="ctrl.model.enable_sso==true">
									<div ng-messages="form.website.$error" role="alert">
										<div ng-message="required">Required</div>
										<div ng-message="url">Use a valid url format, ex: <em>https://example.com</em>
										</div>
									</div>
								</md-input-container>
								<md-input-container flex="100" class="md-input-has-placeholder">
									<label>SAML Metadata URL</label>
									<input type="url" name="website" ng-model="ctrl.model.sso.saml_metadata_url">
									<div ng-messages="form.website.$error" role="alert">
										<div ng-message="url">Use a valid url format, ex: <em>https://example.com</em>
										</div>
									</div>
								</md-input-container>
							</div>
						</section>
					</div>
				</div>
			</div>
			<div class="panel-content md-padding">
				<div layout="row" layout-wrap>
					<md-input-container class="md-block no-animate" flex-xs="100" flex-gt-xs="33" ng-if="ctrl.model.type=='partner'">
						<md-checkbox ng-model="ctrl.model.amazon">Amazon</md-checkbox>
					</md-input-container>
				</div>
				<div layout="row" layout-wrap>
					<md-input-container flex-xs="100" flex-gt-xs="66" flex-gt-lg="33">
						<label>Hide College Name in App Home and Event List</label>
						<md-select name="hide_name" ng-model="ctrl.model.hide_name">
							<md-option ng-repeat="(key, value) in ctrl.mapping.enableDisable" ng-value="value">{{key}}</md-option>
						</md-select>
						<div class="md-errors-spacer"></div>
					</md-input-container>
					<md-input-container flex-xs="100" flex-gt-xs="33">
						<label>Testing</label>
						<md-select name="test" ng-model="ctrl.model.test">
							<md-option ng-repeat="(key, value) in ctrl.mapping.enableDisable" ng-value="value">{{key}}</md-option>
						</md-select>
						<div class="md-errors-spacer"></div>
					</md-input-container>
					<md-input-container flex-xs="100" flex-gt-xs="33">
						<label>Chat</label>
						<md-select name="disable_chat" ng-model="ctrl.model.disable_chat">
							<md-option ng-repeat="(key, value) in ctrl.mapping.reversedEnableDisable" ng-value="value">{{key}}</md-option>
						</md-select>
						<div class="md-errors-spacer"></div>
					</md-input-container>
					<md-input-container flex-xs="100" flex-gt-xs="33" ng-if="ctrl.model.type != 'partner'">
						<label>Chatbot Invites</label>
						<md-select name="block_chatbot_invites" ng-model="ctrl.model.block_chatbot_invites" required>
							<md-option ng-repeat="(key, value) in ctrl.mapping.reversedEnableDisable" ng-value="value">{{key}}</md-option>
						</md-select>
						<div class="md-errors-spacer"></div>
					</md-input-container>

					<md-input-container flex-xs="100" flex-gt-xs="33">
						<label>Milestones</label>
						<md-select name="new_badges_enabled" ng-model="ctrl.model.new_badges_enabled">
							<md-option ng-repeat="(key, value) in ctrl.mapping.enableDisable" ng-value="value">{{key}}</md-option>
						</md-select>
						<div class="md-errors-spacer"></div>
					</md-input-container>
					<md-input-container flex-xs="100" flex-gt-xs="33">
						<label>Milestone Notifications</label>
						<md-select name="task_notifications_disabled" ng-model="ctrl.model.task_notifications_disabled">
							<md-option ng-repeat="(key, value) in ctrl.mapping.reversedEnableDisable" ng-value="value">{{key}}</md-option>
						</md-select>
						<div class="md-errors-spacer"></div>
					</md-input-container>
				</div>
			</div>
			<div class="panel-content md-padding">
				<div layout="row" layout-wrap>
					<div flex="100" toggle-expand="ctrl._$open = (ctrl.model.student_id_policy == 'enabled' || ctrl.model.student_id_policy == 'required')">
						<section layout="column" layout-gt-xs="row">
							<md-input-container class="flex-50 md-input-has-placeholder">
								<label>Student ID Policy</label>
								<md-select name="student_id_policy" ng-model="ctrl.model.student_id_policy" ng-change="ctrl._$open" required>
									<md-option ng-repeat="(key, value) in ctrl.mapping.student_id_policies" ng-value="key">{{value}}</md-option>
								</md-select>
								<div class="md-errors-spacer"></div>
							</md-input-container>
						</section>

						<section class="expandable">
							<div class="indent" layout="column">
								<span class="fake-label">Student ID Validation</span>
								<md-input-container class="md-input-has-placeholder">
									<label>Error Message</label>
									<input type="text" name="error_message" ng-model="ctrl.model.student_id_validation.error_message" ng-required="ctrl._$open">
									<div ng-messages="form.error_message.$error" role="alert">
										<div ng-message="required">Required</div>
									</div>
								</md-input-container>
								<md-input-container class="md-input-has-placeholder">
									<label>Regular Expression</label>
									<input type="text" name="regex_expression" ng-model="ctrl.model.student_id_validation.regex_expression" ng-change="ctrl.validateRegex(form.regex_expression)" ng-required="ctrl._$open">
									<div ng-messages="form.regex_expression.$error" role="alert">
										<div ng-message="required">Required</div>
									</div>
								</md-input-container>
							</div>
						</section>
					</div>
				</div>
			</div>
			<div class="panel-content md-padding">
				<div layout="row" layout-wrap>
					<md-input-container class="flex-100">
						<label>Time Zone</label>
						<md-select name="timezone" required 
							data-md-container-class="select-with-search" 
							ng-model="ctrl.model.timezone" 
							ng-disabled="ctrl.isBusy || ctrl.isLocked"
							md-on-close="ctrl.model.timezone && (ctrl._$timezones = ctrl.queryTimezone(ctrl._$tz='', ctrl.model.timezone))">
							<md-select-header>
								<input type="search" class="header-search" placeholder="Search others" autocomplete="off" 
									aria-label="search others"
									ng-keydown="$event.stopImmediatePropagation()"
									ng-click="$event.stopImmediatePropagation()"
									ng-model-options="{debounce:{'default':300, 'blur':0}, '*':'$inherit'}"
									ng-model="ctrl._$tz" ng-change="ctrl._$timezones = ctrl.queryTimezone(ctrl._$tz, ctrl.model.timezone)">
							</md-select-header>
							<md-option ng-value="value"" ng-repeat=" value in ctrl._$timezones">{{ value }}</md-option>
							<md-option ng-if="!ctrl._$timezones.length" disabled="disabled"><em>No results</em></md-option-group>
						</md-select>
						<div class="md-errors-spacer"></div>
						<div ng-messages="form.timezone.$error" ng-if="form.timezone.$touched">
							<div ng-message="required">Required</div>
							<div ng-message="md-require-match">Invalid time zone</div>
						</div>
					</md-input-container>

					<div flex="100">
						<p class="margin-0x padding-2x text-center" ng-show="ctrl.model.logo_url || ctrl.model.logo_url__file">
							<img ng-src="{{ctrl.model.logo_url}}" ng-if="ctrl.model.logo_url && !ctrl.model.logo_url__file" style="max-width: 300px; max-height: 150px" alt="college logo">
							<img ngf-src="ctrl.model.logo_url__file" ng-if="ctrl.model.logo_url__file" style="max-width: 300px; max-height: 150px" alt="college logo">
						</p>
					</div>
					<div class="flex-100 layout-row">
						<md-input-container class="md-block flex no-animate md-input-has-placeholder" ng-hide="ctrl.model.logo_url__file">
							<label>Logo URL</label>
							<input type="url" class="md-input" name="logo_url" ng-model="ctrl.model.logo_url" ng-required="ctrl.model.type == 'partner' && !ctrl.model.logo_url__file">
							<div ng-messages="form.logo_url.$error" role="alert">
								<div ng-message="required">Required</div>
								<div ng-message="url">Use a valid url format pointing to the image, ex:
									<em>https://example.com/images/logo.png</em>
								</div>
							</div>
						</md-input-container>

						<md-input-container class="md-block flex-nogrow" ng-hide="ctrl.isLocked">
							<md-button type="file" class="md-primary compact" 
								ngf-select accept="image/*" ngf-pattern="'image/*'" ng-disabled="ctrl.isBusy" 
								ngf-multiple="false" ngf-keep="true"
								ngf-change="$newFiles.length ? (ctrl.model.logo_url__file = $newFiles.pop()) : null">
								<i class="fas fa-file-upload"></i> Select File
							</md-button>
						</md-input-container>

						<md-input-container class="md-block flex no-animate" ng-show="ctrl.model.logo_url__file"
							ng-cloak>
							<label>Logo Image</label>
							<input type="text" class="md-input" name="logo_url__file" ng-value="ctrl.model.logo_url__file.name" ng-disabled="true" ng-required="ctrl.model.type == 'partner' && !ctrl.model.logo_url && ctrl.model.logo_alt_text">
							<div ng-messages="form.logo_url__file.$error" role="alert">
								<div ng-message="required">Required</div>
							</div>
						</md-input-container>
						<md-input-container class="md-block flex-nogrow no-animate" ng-show="ctrl.model.logo_url__file" ng-cloak>
							<md-button class="md-icon-button md-warn md-hue-1" 
								aria-label="clear file" title="clear file" 
								ng-click="ctrl.model.logo_url__file = undefined">
								<span class="fas fa-times"></span>
							</md-button>
						</md-input-container>
					</div>
					<md-input-container class="flex-100 md-input-has-placeholder" ng-show="ctrl.model.logo_url || ctrl.model.logo_url__file">
						<label>Logo Alt Text</label>
						<input type="text"
							name="logo_alt_text" minlength="5" 
							aria-label="logo alt text"
							ng-model="ctrl.model.logo_alt_text"
							ng-required="ctrl.model.logo_url || ctrl.model.logo_url__file">
            <div class="md-errors-spacer"></div>
            <div ng-messages="form.logo_alt_text.$error" role="alert">
              <div ng-message="required">Required</div>
              <div ng-message="minlength">Minimum 5 characters</div>
            </div>
            
          </md-input-container>

					<md-input-container flex="100" class="md-input-has-placeholder" ng-if="ctrl.model.type != 'partner'">
						<label>Salesforce ID</label>
						<input type="text" name="salesforce" ng-model="ctrl.model.salesforce_id">
					</md-input-container>
				</div>
				<div layout="row" layout-wrap ng-if="ctrl.model.type == 'partner'">
					<md-input-container flex="100 md-input-has-placeholder">
						<label>Salesforce Partner Abbreviated Short Name</label>
						<input type="text" name="salesforce_shortname" ng-model="ctrl.model.salesforce_shortname" required>
						<div ng-messages="form.salesforce_shortname.$error" role="alert">
							<div ng-message="required">Required</div>
						</div>
					</md-input-container>
					<md-input-container flex="100 md-input-has-placeholder">
						<label>Description</label>
						<textarea name="description" 
							ng-model="ctrl.model.description" 
							rows="3" required></textarea>
						<div ng-messages="form.description.$error" role="alert">
							<div ng-message="required">Required</div>
						</div>
					</md-input-container>
					<md-input-container class="indent" flex="100">
						<md-checkbox ng-model="ctrl.model.display_landing_page" aria-label="Finished?">
							Display Partner Landing Page
						</md-checkbox>
					</md-input-container>

					<div class="flex-100" toggle-expand="ctrl.model.display_landing_page">
						<section class="expandable">
							<md-input-container class="md-block md-input-has-placeholder">
								<label>Landing Page Button Text</label>
								<input type="text" name="landing_page_button_text" ng-model="ctrl.model.landing_page_button_text" ng-required="ctrl.model.display_landing_page">
								<div ng-messages="form.landing_page_button_text.$error" role="alert">
									<div ng-message="required">Required</div>
								</div>
							</md-input-container>
							<md-input-container class="md-block md-input-has-placeholder">
								<label>Landing Page Button URL</label>
								<input type="url" name="landing_page_button_url" ng-model="ctrl.model.landing_page_button_url" ng-required="ctrl.model.display_landing_page">
								<div ng-messages="form.landing_page_button_url.$error" role="alert">
									<div ng-message="required">Required</div>
									<div ng-message="url">Use a valid url format, ex: <em>https://example.com</em></div>
								</div>
							</md-input-container>
						</section>
					</div>
				</div>
			</div>
		</div>

		<div class="panel panel-small" md-whiteframe="1" ng-if="ctrl.model.type != 'partner'">
			<div class="panel-title md-padding md-title normal-wg">Campuses</div>
			<fieldset class="panel-content" ng-disabled="ctrl.isBusy">
				<legend class="hide">Campus</legend>
				<div class="campus md-margin-2 fadeInOnly" ng-repeat="campus in ctrl.model.campuses track by $index">
					<section layout layout-wrap toggle-expand="campus._$open">
						<md-input-container class="md-input-has-placeholder" flex>
							<label>Campus Name</label>
							<input type="text" name="{{ 'campus_'+ $index +'_name' }}" ng-model="campus.name" required>
							<div ng-messages="form['campus_'+ $index +'_name'].$error" role="alert">
								<div ng-message="required">Required</div>
							</div>
						</md-input-container>
						<!-- TODO fix alignment -->
						<md-input-container class="md-input-has-placeholder">
							<label>Status</label>
							<md-select name="status" ng-model="campus.status" ng-disabled="ctrl.isBusy">
								<md-option ng-repeat="(key, value) in ctrl.mapping.status" ng-value="key">{{value}}</md-option>
							</md-select>
							<div class="md-error-spacer"></div>
						</md-input-container>
						<div flex="nogrow" layout layout-align="center center">
							<md-button class="md-icon-button md-warn md-hue-1 md-margin-0"
								ng-click="ctrl.removeCampus($index, $event)" 
								aria-label="delete campus" title="delete campus">
								<i class="far fa-trash-alt"></i>
							</md-button>
						</div>

					</section>
					<section class="indent" layout layout-align="space-between start" layout-wrap>
						<md-input-container flex-xs="100" class="md-input-has-placeholder" flex-gt-xs="33" flex-gt-md="20">
							<label>Phone</label>
							<input type="text" name="{{ 'campus_'+ $index +'_phone' }}" ng-model="campus.phone">
						</md-input-container>
						<md-input-container flex-xs="100" flex-gt-xs="66" flex-gt-md="40" class="md-input-has-placeholder">
							<label>Website</label>
							<input type="url" name="{{ 'campus_'+ $index +'_website' }}" ng-model="campus.website">
						</md-input-container>
						<md-input-container flex-xs="100" flex-gt-xs="33" flex-gt-md="10" class="md-input-has-placeholder">
							<label>Zip Code</label>
							<input type="number" name="{{ 'campus_'+ $index +'_zip_code' }}" ng-model="campus.zip_code">
						</md-input-container>
						<md-input-container flex-xs="100" flex-gt-xs="33" flex-gt-md="15" class="md-input-has-placeholder">
							<label>Latitude</label>
							<input type="number" name="{{ 'campus_'+ $index +'_latitude' }}" step="0.0000001" ng-model="campus.latitude">
						</md-input-container>
						<md-input-container flex-xs="100" flex-gt-xs="33" flex-gt-md="15" class="md-input-has-placeholder">
							<label>Longitude</label>
							<input type="number" name="{{ 'campus_'+ $index +'_longitude' }}" step="0.0000001" ng-model="campus.longitude">
						</md-input-container>
					</section>
				</div>
				<div class="md-margin-2 text-center">
					<md-button class="md-primary md-raised md-hue-3" ng-click="ctrl.addNewCampus(true)" ng-disabled="ctrl.isBusy">
						<span class="fas fa-plus-circle"></span>
						Add Campus
					</md-button>
				</div>
			</fieldset>
		</div>

		<div class="panel panel-small" md-whiteframe="1" ng-if="ctrl.model.type != 'partner'">
			<div class="panel-title md-padding md-title normal-wg">Contracts</div>
			<fieldset class="panel-content" ng-disabled="ctrl.isBusy">
				<legend class="hide">Contracts</legend>
				<div class="contract md-margin-2 fadeInOnly" ng-repeat="contract in ctrl.model.contracts track by $index">
					<div layout>
						<md-input-container flex class="md-input-has-placeholder">
							<label>Plan</label>
							<input type="text" name="{{ 'contract_'+ $index +'_plan' }}" ng-model="contract.plan" required>
							<div ng-messages="form['contract_'+ $index +'_plan'].$error" role="alert">
								<div ng-message="required">Required</div>
							</div>
						</md-input-container>
						<div flex="nogrow" flex-order-gt-xs="2" layout layout-align="center center">
							<md-button class="md-icon-button md-warn md-hue-1 md-margin-0"
								ng-click="ctrl.removeContract($index, $event)" 
								aria-label="delete contract" title="delete contract" 
								ng-show="ctrl.model.contracts.length > 1">
								<i class="far fa-trash-alt"></i>
							</md-button>
						</div>
					</div>
					<div layout layout-wrap>
						<md-input-container class="flex-xs-100 flex-gt-xs-33 md-input-has-placeholder">
							<label>Notifications Limit</label>
							<input type="number" name="{{ 'contract_'+ $index +'_total_allowed_notifications' }}" ng-model="contract.total_allowed_notifications" required>
							<div ng-messages="form['contract_'+ $index +'_total_allowed_notifications'].$error" role="alert">
								<div ng-message="required">Required</div>
							</div>
						</md-input-container>
						<md-input-container class="flex-xs-50 flex-gt-xs-33 md-input-has-placeholder" ng-model-options="{debounce: 1000}">
							<label>Start Date</label>
							<md-icon class="far fa-calendar"></md-icon>
							<input type="text" name="{{ 'contract_'+$index+'_start_date' }}" required 
								date-picker
								valid-date ng-model="contract.start_date">
							<div ng-messages="form['contract_'+$index+'_start_date'].$error" role="alert">
								<!-- directive: use-template TEMPLATES.COMMON.VALID_DATE_MSGS -->
							</div>
						</md-input-container>
						<md-input-container class="flex-xs-50 flex-gt-xs-33 md-input-has-placeholder" ng-model-options="{debounce: 1000}">
							<label>End Date</label>
							<md-icon class="far fa-calendar"></md-icon>
							<input type="text" name="{{ 'contract_'+$index+'_end_date' }}" required date-picker
								valid-date ng-model="contract.end_date">
							<div ng-messages="form['contract_'+$index+'_end_date'].$error" role="alert">
								<!-- directive: use-template TEMPLATES.COMMON.VALID_DATE_MSGS -->
							</div>
						</md-input-container>
					</div>
				</div>
				<div class="md-margin-2 text-center">
					<md-button class="md-primary md-raised md-hue-3" ng-click="ctrl.addNewContract(true)" ng-disabled="ctrl.isBusy">
						<span class="fas fa-plus-circle"></span>
						Add Contract
					</md-button>
				</div>
			</fieldset>
		</div>

		<div class="panel panel-small" md-whiteframe="1" ng-if="ctrl.model.type != 'partner'">
			<div class="panel-title md-padding md-title normal-wg">Goals</div>
			<fieldset class="panel-content" ng-disabled="ctrl.isBusy">
				<legend class="hide">Goals</legend>
				<div class="goal md-margin-2 fadeInOnly" ng-repeat="goal in ctrl.model.goals track by $index" ng-init="dateExample=moment().format(SETTINGS.dateFormatYM)">
					<div layout>
						<md-input-container class="flex-100 md-input-has-placeholder">
							<label>Target Student Count</label>
							<input type="number" name="{{ 'goal_'+ $index +'_target' }}" ng-model="goal.target" required>
							<div ng-messages="form['goal_'+ $index +'_target'].$error" role="alert">
								<div ng-message="required">Required</div>
							</div>
						</md-input-container>
						<div flex="nogrow" layout layout-align="center center">
							<md-button class="md-icon-button md-warn md-hue-1 md-margin-0"
								ng-click="ctrl.removeGoal($index, $event)" 
								aria-label="delete goal" title="delete goal">
								<i class="far fa-trash-alt"></i>
							</md-button>
						</div>
					</div>
					<div layout layout-wrap>
						<md-input-container class="flex-50 md-input-has-placeholder" ng-model-options="{debounce: 1000}">
							<label>Start Date</label>
							<input type="text" name="{{ 'goal_'+$index+'_start_date' }}" required ng-model="goal.start_date" 
								valid-date view-format="{{::SETTINGS.dateFormatYM}}">
							<div ng-messages="form['goal_'+$index+'_start_date'].$error" role="alert">
								<!-- directive: use-template TEMPLATES.COMMON.VALID_DATE_MSGS -->
							</div>
						</md-input-container>
						<md-input-container class="flex-50 md-input-has-placeholder"
							ng-model-options="{debounce: 1000}">
							<label>End Date</label>
							<input type="text" name="{{ 'goal_'+$index+'_end_date' }}" required ng-model="goal.end_date"
								valid-date view-format="{{::SETTINGS.dateFormatYM}}">
							<div ng-messages="form['goal_'+$index+'_end_date'].$error" role="alert">
								<!-- directive: use-template TEMPLATES.COMMON.VALID_DATE_MSGS -->
							</div>
						</md-input-container>
					</div>
				</div>
				<div class="md-margin-2 text-center">
					<md-button class="md-primary md-raised md-hue-3" ng-click="ctrl.addNewGoal(true)"mng-disabled="ctrl.isBusy">
						<span class="fas fa-plus-circle"></span>
						Add Goal
					</md-button>
				</div>
			</fieldset>
		</div>

		<div class="panel panel-small" md-whiteframe="1" ng-if="ctrl.model.type != 'partner'">
			<div class="panel-title md-padding md-title normal-wg">Resources</div>
			<fieldset class="panel-content" ng-disabled="ctrl.isBusy">
				<legend class="hide">Resources</legend>
				<div class="resource md-margin-2 fadeInOnly" ng-repeat="rsrc in ctrl.model.resources track by $index">
					<section layout layout-wrap toggle-expand="rsrc._$open">
						<md-input-container class="md-input-has-placeholder" flex>
							<label>Name</label>
							<input type="text" name="{{ 'resource_'+ $index +'_name' }}" ng-model="rsrc.name" valid-lang required>
							<div ng-messages="form['resource_'+ $index +'_name'].$error" role="alert">
								<div ng-message="required">Required</div>
								<div ng-message="lang-missing">{{ form['resource_'+ $index +'_name'].$error['lang-missing'] }}</div>
                                                               <div ng-message="lang-mismatch">{{ form['resource_'+ $index +'_name'].$error['lang-mismatch'] }}</div>
								<div ng-message="lang">Invalid language tag formatting</div>
							</div>
						</md-input-container>
						<div flex="nogrow" flex-order-gt-xs="2" layout layout-align="center center">
							<md-button class="md-icon-button md-warn md-hue-1 md-margin-0" 
                                                               ng-click="ctrl.removeResource($index, $event)" 
                                                               aria-label="delete resource" title="delete resource">
								<i class="far fa-trash-alt"></i>
							</md-button>
						</div>
					</section>
					<section class="indent" layout layout-wrap>
						<md-input-container class="md-input-has-placeholder" flex="100">
							<label>Description</label>
							<textarea name="{{ 'resource_'+ $index +'_description' }}" valid-lang ng-model="rsrc.description" rows="2"></textarea>
              <div ng-messages="form['resource_'+ $index +'_description'].$error" role="alert">
                <div ng-message="lang-missing">{{ form['resource_'+ $index +'_description'].$error['lang-missing'] }}</div>
                                                               <div ng-message="lang-mismatch">{{ form['resource_'+ $index +'_description'].$error['lang-mismatch'] }}</div>
								<div ng-message="lang">Invalid language tag formatting</div>
							</div>
						</md-input-container>
						<div class="compact" flex="100" ng-class="{'flex-gt-md-50': ctrl.hasMap(rsrc)}" layout layout-wrap layout-align="start start">
							<md-input-container class="md-input-has-placeholder" flex="100">
								<md-autocomplete flex="100" md-input-name="{{ (_$location = 'resource_'+ $index +'_location') }}" input-aria-labelledby="
find location"
                                                                       ng-disabled="ctrl.isBusy || ctrl.isLocked"
                                                                       md-floating-label="Physical Address"
                                                                       md-input-class="valid-lang"
                                                                       md-no-float="false"
									md-search-text-change="" md-search-text="rsrc.location.label"
									md-selected-item-change="ctrl.selectLocation(rsrc, item)"
									md-selected-item="rsrc._$selected"
									md-items="item in ctrl.searchLocation(rsrc, rsrc.location.label)"
									md-item-text="item.formatted_address" md-no-cache="true" md-min-length="2"
									md-delay="500">
									<md-item-template>
										<i class="fas fa-map-marker-alt"></i> <span
											md-highlight-text="rsrc.location.label"
											md-highlight-flags="^i">{{item.formatted_address}}</span>
									</md-item-template>
									<md-not-found>Cannot find "{{rsrc.location.label}}". Please use the map to mark
										it.</md-not-found>
									<div ng-messages="form[_$location].$error" role="alert">
										<div ng-message="required">Required</div>
										<div ng-message="lang-missing">{{ form[_$location].$error['lang-missing'] }}
										</div>
										<div ng-message="lang-mismatch">{{ form[_$location].$error['lang-mismatch'] }}
										</div>
										<div ng-message="lang">Invalid language tag formatting</div>
									</div>
								</md-autocomplete>
							</md-input-container>

							<md-input-container class="md-input-has-placeholder" flex-xs="100" flex-gt-xs="50"
								ng-class="{'flex-gt-md-100': ctrl.hasMap(rsrc)}">
								<label>Latitude</label>
								<input type="text" name="{{ 'resource_'+ $index +'_latitude' }}"
									ng-model="rsrc.location.latitude" ng-required="ctrl.isCoordRequired(rsrc)"
									ng-pattern="/^-?\d+\.?\d*$/">
								<div ng-messages="form['resource_'+ $index +'_latitude'].$error" role="alert">
									<div ng-message="required">Required</div>
									<div ng-message="pattern">Invalid value</div>
								</div>
							</md-input-container>
							<md-input-container class="md-input-has-placeholder" flex-xs="100" flex-gt-xs="50"
								ng-class="{'flex-gt-md-100': ctrl.hasMap(rsrc)}">
								<label>Longitude</label>
								<input type="text" name="{{ 'resource_'+ $index +'_longitude' }}"
									ng-model="rsrc.location.longitude" ng-required="ctrl.isCoordRequired(rsrc)"
									ng-pattern="/^-?\d+\.?\d*$/">
								<div ng-messages="form['resource_'+ $index +'_longitude'].$error" role="alert">
									<div ng-message="required">Required</div>
									<div ng-message="pattern">Invalid value</div>
								</div>
							</md-input-container>
						</div>
						<div flex="100" flex-gt-md="50" ng-show="ctrl.hasMap(rsrc)">
							<div id="map_{{ rsrc._id }}" class="google_map margin-2x"
								style="height:300px; background:#DDD"></div>
						</div>

						<md-input-container class="md-input-has-placeholder" flex-xs="100" flex-gt-xs="30"
							ng-model-options="{updateOn:'default blur', debounce:{'default':200,'blur':0}}">
							<label>Phone Number</label>
							<input type="text" name="{{ 'resource_'+ $index +'_phone' }}" ng-model="rsrc.phone_number"
								ng-pattern="ctrl._$mobileRegex"
								ng-keyup="ctrl.cleanMobileNumber(form['resource_'+ $index +'_phone'])"
								ng-paste="ctrl.cleanMobileNumber(form['resource_'+ $index +'_phone'])">
							<div ng-messages="form['resource_'+ $index +'_phone'].$error" role="alert">
								<div ng-message="pattern">Invalid format (e.g. 1234567890 or +52123456789)</div>
							</div>
						</md-input-container>
						<md-input-container class="md-input-has-placeholder" flex-xs="100" flex-gt-xs="70">
							<label>URL</label>
							<input type="url" name="{{ 'resource_'+ $index +'_link_url' }}" ng-model="rsrc.link_url">
							<div ng-messages="form['resource_'+ $index +'_link_url'].$error" role="alert">
								<div ng-message="required">Required</div>
								<div ng-message="url">Use a valid url format, ex: <em>https://example.com</em></div>
							</div>
						</md-input-container>
						<md-input-container class="flex-100 md-input-has-placeholder">
							<label>Tags</label>
							<md-select name="tags" aria-label="tags" role="listbox" multiple ng-model="rsrc.tags"
								ng-model-options="{trackBy: '$value'}" ng-disabled="ctrl.isBusy || ctrl.isLocked">
								<md-option ng-repeat="item in ctrl.mapping.flutterTags" ng-value="item._id">{{
									item.name.en_US }}</md-option>
							</md-select>
							<div class="md-errors-spacer"></div>
						</md-input-container>
					</section>
				</div>
				<div class="md-margin-2 text-center">
					<md-button class="md-primary md-raised md-hue-3" ng-click="ctrl.addNewResource(true)"
						ng-disabled="ctrl.isBusy">
						<span class="fas fa-plus-circle"></span>
						Add a New Resource
					</md-button>
				</div>
			</fieldset>
		</div>

	</main>

	<div ng-show="ctrl.isBusy" class="busy-overlay fade-in fade-out" layout layout-align="center center">
		<md-progress-circular md-mode="indeterminate"></md-progress-circular>
	</div>

	<footer md-whiteframe="2" layout="row" layout-align="end start">
		<md-button type="submit" class="md-raised md-warn" ng-disabled="ctrl.isBusy">
			<span class="far fa-save"></span> Save
		</md-button>
	</footer>
</form>